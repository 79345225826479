@media screen and  (min-width: 961px) {
    .timeline-outer-div {
        /* background-color: aliceblue; */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: center;
    }

    .timeline-inner-div {
        background-color: white;
        min-width: 1200px;
        max-width: 1200px;
        margin-top: 200px;
        min-height: 600px;
        max-height: 80vh;
        overflow: hidden;
        display: flex;
        /* flex-direction: column; */
        justify-content: space-between;
        overflow: hidden;
        border: 2px solid black;
    }

    .timeline-inner-top {
        position: relative;
        height: 100%;
        width: 40%;
        display: flex;
        overflow: hidden;
    }

    .timeline-inner-bottom {
        display: flex;
        justify-content: center;
        gap: 2em;
        width: 60%;
        overflow: hidden;
    }

    .timeline-text-wrapper {
        /* background-color: antiquewhite; */
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 85%;
        margin-left: 50px;
    }

    .timeline-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
        margin-top: 20%;
    }

    .timeline-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }
  
    .timeline-main-copy {
        margin-top: 10%;
        font-weight: 300;
        font-size: 1em;
        line-height: 1.5;
        width: 90%;
    }

    .timeline-stages-inner-wrapper {
        /* background-color: antiquewhite; */
        margin-top: 10vh;
        min-height: 500px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

   .timeline-stages-inner-wrapper:nth-child(2) {
       margin-top: 20vh;
   }

    .timeline-stages-inner-wrapper:nth-child(3) {
       margin-top: 30vh;
   }

    .timeline-stage-div {
        /* border: 1px solid black; */
        /* height: 150px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #line {
        width: 60px;
    }

    .timeline-stage-div > h1 {
        font-weight: 600;
        font-size: 0.8em;
        padding-bottom: 1em;
    }

    .timeline-stage-div > h2 {
        font-size: 1.3em;
        padding-bottom: 2em;
        color: #5b7cfc;
        font-weight: 600;
    }

    .img-wrapper {
        display: inline-block;
        width: 80%;
        height: 300px;
        border-radius: 190px;
        /* outline: 1px solid black;
        outline-offset: 8px; */
        overflow: hidden;
        position: relative;
    }

    .timeline-stage-img {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50%;
        overflow: hide;
    }

    .timeline-stage-img:hover {
        transform: scale(1.1);
        transition: all 0.4s ease-in-out;
    }
    

 
}

@media screen and (min-width: 421px) and (max-width: 960px) {
    .timeline-outer-div {
        /* background-color: aliceblue; */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: center;
    }

    .timeline-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        /* min-height: 80vh;
        max-height: 80vh; */
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        border: 2px solid black;
    }

    .timeline-inner-top {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        overflow: hidden;
    }

    .timeline-inner-bottom {
        display: flex;
        justify-content: center;
        gap: 2em;
        width: 100%;
        overflow: hidden;
    }

    .timeline-text-wrapper {
        /* background-color: antiquewhite; */
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 85%;
        margin-left: 50px;
    }

    .timeline-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
        margin-top: 20%;
    }

    .timeline-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .timeline-main-copy {
        margin-top: 10%;
        font-weight: 300;
        font-size: 1em;
        line-height: 1.5;
        width: 90%;
    }

    .timeline-stages-inner-wrapper {
        /* background-color: antiquewhite; */
        margin-top: 10vh;
        min-height: 500px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

   .timeline-stages-inner-wrapper:nth-child(2) {
       margin-top: 20vh;
   }

    .timeline-stages-inner-wrapper:nth-child(3) {
       margin-top: 30vh;
   }

    .timeline-stage-div {
        /* border: 1px solid black; */
        /* height: 150px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #line {
        width: 60px;
    }

    .timeline-stage-div > h1 {
        font-weight: 600;
        font-size: 0.8em;
        padding-bottom: 1em;
    }

    .timeline-stage-div > h2 {
        font-size: 1.3em;
        padding-bottom: 2em;
        color: #5b7cfc;
        font-weight: 600;
    }

    .img-wrapper {
        display: inline-block;
        width: 80%;
        height: 300px;
        border-radius: 190px;
        /* outline: 1px solid black;
        outline-offset: 8px; */
        overflow: hidden;
        position: relative;
    }

    .timeline-stage-img {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50%;
        overflow: hide;
    }

    .timeline-stage-img:hover {
        transform: scale(1.1);
        transition: all 0.4s ease-in-out;
    }
 
}

@media screen and  (max-width: 420px) {
    .timeline-outer-div {
        /* background-color: aliceblue; */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: center;
    }

    .timeline-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        border: 2px solid black;
    }

    .timeline-inner-top {
        /* background-color: antiquewhite; */
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .timeline-inner-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* gap: 2em; */
        width: 80%;
        overflow: hidden;
    }

    .timeline-text-wrapper {
        /* background-color: antiquewhite; */
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 85%;
    }

    .timeline-header {
        font-size: 2em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
        margin-top: 20%;
    }

    .timeline-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .timeline-main-copy {
        margin-top: 10%;
        font-weight: 300;
        font-size: 1em;
        line-height: 1.5;
        width: 90%;
    }

    .timeline-stages-inner-wrapper {
        /* background-color: antiquewhite; */
        min-height: 500px;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    .timeline-stages-inner-wrapper:first-child {
        margin-top: 10vh;
    }

    .timeline-stage-div {
        /* border: 1px solid black; */
        /* height: 150px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #line {
        width: 60px;
    }

    .timeline-stage-div > h1 {
        font-weight: 600;
        font-size: 0.8em;
        padding-bottom: 1em;
    }

    .timeline-stage-div > h2 {
        font-size: 1.3em;
        padding-bottom: 2em;
        color: #5b7cfc;
        font-weight: 600;
    }

    .img-wrapper {
        display: inline-block;
        width: 80%;
        height: 300px;
        border-radius: 190px;
        /* outline: 1px solid black;
        outline-offset: 8px; */
        overflow: hidden;
        position: relative;
    }

    .timeline-stage-img {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50%;
        overflow: hide;
    }

    .timeline-stage-img:hover {
        transform: scale(1.1);
        transition: all 0.4s ease-in-out;
    }
 
}

