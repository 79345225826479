@media screen and (min-width: 961px) {
    .advertise-outer-div {
        /* background-color: rgb(44, 44, 44); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .advertise-inner-div {
        background-color: white;
        min-width: 1200px;
        max-width: 1200px;
        margin-top: 20vh;
        /* min-height: 80vh;
        max-height: 80vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black;
    }


    .advertise-top-div {
        /* background-color: antiquewhite; */
        width: 100%;
        min-height: 40vh;
        display: flex;
        overflow: hidden;
        border-bottom: 2px solid black;
    }

    .advertise-bottom-div {
        width: 100%;
        display: block;
        height: 350px;
        overflow: hidden;
    }

    .advertise-top-left-div, .advertise-top-right-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40vh;
        overflow: hidden;
    }


    .advertise-top-left-div {
        width: 40%;
    }

    .advertise-top-right-div {
        width: 60%;
        border-left: 2px solid black;
    }

    .advertise-header-div {
               /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%;
    }

    .advertise-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .advertise-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }


    .advertise-descrip-div {
        width: 90%;
        padding: 0 4em;
    }
  
    .advertise-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

    .advertise-cta {
        font-weight: 600;
        margin-top: 50px;
        color:#5b7cfc;
        padding-bottom: 50px;
    }

    .advertise-right-div {
        width: 60%;
        display: flex;
        justify-content: center;
        gap: 3rem;
        position: relative;
        overflow: hidden;
        height: 500px;
    }

    .agency-outer-wrapper {
        /* background-color: antiquewhite; */
        min-width: 100%;
        max-width: 100%;
        min-height: 350px;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .agency-slide-wrapper-inactive {
        border-right: 2px solid black;
        /* width: 100px; */
        min-height: 350px;
        position: relative;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
    }

    .agency-slide-wrapper-active {
        flex: 5;
        min-height: 350px;
        z-index: 50;
        border-right: 2px solid black;
    }

    .agency-slide-wrapper-inactive:last-child, .agency-slide-wrapper-active:last-child {
        border-right: none;
    }

    .agency-slide-inner-div {
       min-height: 350px;
       display: flex;
       overflow: hidden;
    }

    .agency-slider-inner-left {
       background-color: black;
       width: 400px;
       height: 350px;
       overflow: hidden;
       display: block;
    }

    .agency-img {
        width: 500px;
        object-fit: cover;
        overflow: hidden;
    }

    .draft-img {
        width: 100%;
        height: 100%;
        object-position: 50%;
        object-fit: cover;
        overflow: hidden;
    }


    .agency-slider-inner-mid {
        min-width: 120px;
        max-width: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .agency-slider-inner-right-inactive {
        display: none;
    }

    .agency-slider-inner-right-active {
        width: 300px;
        background-color: white;
    }

    .agency-name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.1em;
        margin-top: 20px;
        padding: 0 25px;
        writing-mode: vertical-rl;
    }

    .agency-name:hover {
        color: #5b7cfc;
        transition: all 0.2s ease-in-out;
    }

    .agency-name svg {
        transform: rotate(180deg);
        margin-top: 20px;
        fill: #389CF4;
        font-size: 1em;
    }

    .agency-close-button {
        background-color: #389cf400;
        border: none;
        width: 100px;
        height: 25px;
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        border-left: 2px solid black;
    }

    .agency-close-button:hover {
        background-color: #5b7cfc;
        color: white;
    }

    .agency-details-outer-wrapper {
        /* background-color: antiquewhite; */
        position: relative;
        height: 100%;
        margin-left: 30px;
    }

    .details-header, .details-header-timing {
        padding: 40px 0 20px 0;
        font-size: 15px;
        font-weight: 600;
    }

    .details-header-timing {
        font-family: 'Poppins', Helvetica, sans-serif;
    }

    .details-role {
        padding-bottom: 20px;
        line-height: 1.3;
    }

    .role-name {
        font-weight: 600;
    }

    .role-name span {
        font-weight: 300;
    }

  

}

@media screen and (min-width: 421px) and (max-width: 960px) {
    .advertise-outer-div {
        /* background-color: rgb(44, 44, 44); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .advertise-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black;
    }


    .advertise-top-div {
        /* background-color: antiquewhite; */
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 2px solid black;
    }

    .advertise-bottom-div {
        width: 100%;
        display: block;
        overflow: hidden;
    }

    .advertise-top-left-div, .advertise-top-right-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40vh;
        overflow: hidden;
    }


    .advertise-top-left-div {
        border-bottom: 2px solid black;
    }

    .advertise-header-div {
               /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%;
    }

    .advertise-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .advertise-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }


    .advertise-descrip-div {
        width: 80%;
    }
  
    .advertise-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

    .advertise-cta {
        font-weight: 600;
        margin-top: 50px;
        color: #5b7cfc;
        padding-bottom: 50px;
    }

  
    .agency-outer-wrapper {
        /* background-color: antiquewhite; */
        min-width: 100%;
        max-width: 100%;
        /* height: 1000px; */
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .agency-slide-wrapper-inactive {
        width: 100%;
        max-height: 350px;
        position: relative;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
    }

    .agency-slide-wrapper-active {
        flex: 5;
        z-index: 50;
    }

    .agency-slide-wrapper-inactive:last-child, .agency-slide-wrapper-active:last-child {
        border-right: none;
    }

    .agency-slide-inner-div {
       display: flex;
       width: 100%;
       min-height: 350px;
       max-height: 350px;
       overflow: hidden;
    }

    .agency-slider-inner-left {
       background-color: black;
       min-width: 70%;
       overflow: hidden;
       display: block;
    }

    .agency-slider-inner-left-mobile {
       width: 100px;
       overflow: hidden;
       display: block;
    }

    .agency-img, .draft-img {
        width: 100%;
        height: 100%;
        object-position: 50%;
        object-fit: cover;
        overflow: hidden;
    }


    .agency-slider-inner-mid {
        max-width: 120px;
        overflow: hidden;
    }

    .agency-slider-inner-right-inactive {
        display: none;
    }

    .agency-slider-inner-right-active {
        width: 85%;
        background-color: white;
    }

    .agency-name {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.1em;
        margin-top: 20px;
        padding: 0 25px;
        writing-mode: vertical-rl;
    }

    .agency-name:hover {
        color: #5b7cfc;
        transition: all 0.2s ease-in-out;
    }

    .agency-name svg {
        transform: rotate(180deg);
        margin-top: 20px;
        fill: #5b7cfc;
        font-size: 1em;
    }

    .agency-close-button {
        background-color: #389cf400;
        border: none;
        width: 100px;
        height: 25px;
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        border-left: 2px solid black;
    }

    .agency-close-button:hover {
        background-color: #5b7cfc;
        color: white;
    }

    .agency-details-outer-wrapper {
        /* background-color: antiquewhite; */
        position: relative;
        height: 100%;
        margin-left: 30px;
    }

    .details-header, .details-header-timing {
        padding: 40px 0 20px 0;
        font-size: 15px;
        font-weight: 600;
    }

    .details-header-timing {
        font-family: 'Poppins', Helvetica, sans-serif;
    }

    .details-role {
        padding-bottom: 20px;
        line-height: 1.3;
    }

    .role-name {
        font-weight: 600;
    }

    .role-name span {
        font-weight: 300;
    }

  

}

@media screen and (max-width: 420px) {
    .advertise-outer-div {
        /* background-color: rgb(44, 44, 44); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .advertise-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black;
    }


    .advertise-top-div {
        /* background-color: antiquewhite; */
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 2px solid black;
    }

    .advertise-bottom-div {
        width: 100%;
        display: block;
        overflow: hidden;
    }

    .advertise-top-left-div, .advertise-top-right-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40vh;
        overflow: hidden;
    }


    .advertise-top-left-div {
        border-bottom: 2px solid black;
    }

    .advertise-header-div {
               /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%;
    }

    .advertise-header {
        font-size: 2.8em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .advertise-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }


    .advertise-descrip-div {
        width: 80%;
    }
  
    .advertise-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

    .advertise-cta {
        font-weight: 600;
        margin-top: 50px;
        color: #5b7cfc;
        padding-bottom: 50px;
    }

  
    .agency-outer-wrapper {
        /* background-color: antiquewhite; */
        min-width: 100%;
        max-width: 100%;
        /* height: 1000px; */
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .agency-slide-wrapper-inactive {
        width: 100%;
        max-height: 350px;
        position: relative;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
    }

    .agency-slide-wrapper-active {
        flex: 5;
        z-index: 50;
    }

    .agency-slide-wrapper-inactive:last-child, .agency-slide-wrapper-active:last-child {
        border-right: none;
    }

    .agency-slide-inner-div {
       display: flex;
       min-height: 350px;
       max-height: 350px;
       overflow: hidden;
    }

    .agency-slider-inner-left {
       background-color: black;
       width: 500px;
       overflow: hidden;
       display: block;
    }

    .agency-slider-inner-left-mobile {
       width: 100px;
       overflow: hidden;
       display: block;
    }

    .agency-img, .draft-img {
        width: 100%;
        height: 100%;
        object-position: 50%;
        object-fit: cover;
        overflow: hidden;
    }


    .agency-slider-inner-mid {
        max-width: 120px;
        overflow: hidden;
    }

    .agency-slider-inner-right-inactive {
        display: none;
    }

    .agency-slider-inner-right-active {
        width: 85%;
        background-color: white;
    }

    .agency-name {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.1em;
        margin-top: 20px;
        padding: 0 25px;
        writing-mode: vertical-rl;
    }

    .agency-name:hover {
        color: #5b7cfc;
        transition: all 0.2s ease-in-out;
    }

    .agency-name svg {
        transform: rotate(180deg);
        margin-top: 20px;
        fill: #5b7cfc;
        font-size: 1em;
    }

    .agency-close-button {
        background-color: #389cf400;
        border: none;
        width: 100px;
        height: 25px;
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        border-left: 2px solid black;
    }

    .agency-close-button:hover {
        background-color: #5b7cfc;
        color: white;
    }

    .agency-details-outer-wrapper {
        /* background-color: antiquewhite; */
        position: relative;
        height: 100%;
        margin-left: 30px;
    }

    .details-header, .details-header-timing {
        padding: 40px 0 20px 0;
        font-size: 15px;
        font-weight: 600;
    }

    .details-header-timing {
        font-family: 'Poppins', Helvetica, sans-serif;
    }

    .details-role {
        padding-bottom: 20px;
        line-height: 1.3;
    }

    .role-name {
        font-weight: 600;
    }

    .role-name span {
        font-weight: 300;
    }

  

}
