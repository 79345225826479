@media screen and (min-width: 961px) {
    .navbar-outer-div {
        background-color: rgb(255, 255, 255);
        width: 100vw;
        height: 100px;
        display: flex;
        position: sticky;
        align-items: flex-end;
        top: 0;
        justify-content: center;
        z-index: 150;
    }

    .navbar-inner-div-border {
        background-color: rgb(255, 255, 255);
        border: 2px solid black;
        min-width: 1200px;
        max-width: 1200px;
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    }

    .logo-div {
        min-width: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #logo {
        /* letter-spacing: 0.1em; */
        font-weight: 600;
        font-size: 1.6rem;
        cursor: pointer;
        color: white;
        -webkit-text-stroke: 1px black;
          text-shadow:
        3px 3px 0 #000,
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    }

    #logo:hover {
       -webkit-text-stroke: 1px #5b7cfc;
          text-shadow:
        3px 3px 0 #5b7cfc,
        -1px -1px 0 #5b7cfc,  
        1px -1px 0 #5b7cfc,
        -1px 1px 0 #5b7cfc,
        1px 1px 0 #5b7cfc;
    }

    .links-div {
        /* background-color: green; */
        display: flex;
        align-items: center;
        height: 100%;
    }

    #nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 140px;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 1em;
        cursor: pointer;
        text-transform: uppercase;
        border-left: 2px solid black;
    }

    #nav-link:last-child {
        color: #5b7cfc;
    }

    #nav-link:last-child:hover {
        transition: all 0.2s ease-in-out ;
    }

    #nav-link:hover {
        font-weight: 600;
        transition: all 0.2s ease-in-out ;
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 2px;
        text-decoration-thickness: 4px;
        cursor: pointer;
   }



}

@media screen and (max-width: 960px) {
    .navbar-outer-div {
        background-color: rgb(255, 255, 255);
        width: 100vw;
        height: 80px;
        display: flex;
        position: sticky;
        align-items: flex-end;
        top: 0;
        justify-content: center;
        z-index: 150;
    }

    .navbar-inner-div-border {
        background-color: rgb(255, 255, 255);
        width: 90vw;
        height: 50px;
        position: sticky;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid black;
    }

    .logo-div {
        height: 50px;
        min-width: 100px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    
    #logo {
        /* letter-spacing: 0.1em; */
        font-weight: 600;
        font-size: 2em;
        cursor: pointer;
        color: white;
         -webkit-text-stroke: 1px #5b7cfc;
          text-shadow:
        3px 3px 0 #5b7cfc,
        -1px -1px 0 #5b7cfc,  
        1px -1px 0 #5b7cfc,
        -1px 1px 0 #5b7cfc,
        1px 1px 0 #5b7cfc;
    }

    #logo:hover {
       -webkit-text-stroke: 1px #5b7cfc;
          text-shadow:
        3px 3px 0 #5b7cfc,
        -1px -1px 0 #5b7cfc,  
        1px -1px 0 #5b7cfc,
        -1px 1px 0 #5b7cfc,
        1px 1px 0 #5b7cfc;
    }

    .links-div {
        display: none;
    }

    .mobile-menu-icon-div {
        font-size: 2em;
        display: flex;
        padding: 0 1em
    }

    .links-mobile-div  {
        display: flex;
        position: absolute;
        top: 55px;
        flex-direction: column;
        height: 50vh;
    }

    .links-inner-mobile-div {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        width: 90vw;
    }

    #nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vh;
        width: 100%;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 2em;
        color: #5b7cfc;
        cursor: pointer;
        text-transform: uppercase;
        border-bottom: 2px solid rgb(0, 0, 0);
    }

    /* #nav-link:first-child {
        border-top: 2px solid black;
    } */



}
