@media screen and (min-width: 961px) {
    .market-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .market-inner-div {
        background-color: white;
        min-width: 1200px;
        max-width: 1200px;
        margin-top: 20vh;
        min-height: 80vh;
        max-height: 80vh;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black
    }

    .market-left-div {
       min-height: 80vh;
       width: 40%;
       display: flex;
       flex-direction: column;
       border-right: 2px solid black;
    }

    .market-left-top-div, .market-left-bottom-div {
        width: 100%;
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .market-left-top-div {
        border-bottom: 2px solid black;
    }

   .market-right-div {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .market-header-div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%; 
    }

    .market-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .market-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .market-descrip-div {
        width: 80%;
    }

    .market-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

    .resume-email {
        font-weight: 600;
        line-height: 1.5;
        padding-left: 5px;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .market-video-wrapper {
        /* background-color: #5b7cfc; */
        width: 100%;
        height: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }

    .market-video-wrapper > div {
       margin-bottom: 5em;
       box-shadow: rgba(91, 124, 252, 0.8) 5px 5px, rgba(97, 129, 253, 0.5) 10px 10px, rgba(134, 158, 250, 0.3) 15px 15px, rgba(154, 173, 250, 0.2) 20px 20px, rgba(187, 199, 250, 0.1) 25px 25px;
    }

    .youtube-header {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.3;
        padding: 3em;
        text-align: center;
    }

    .youtube-header a {
        font-weight: 600;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        padding: 0 10px;
    }


    
}

@media screen  and (min-width: 421px) and (max-width: 960px) {
    .market-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .market-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black
    }

    .market-left-div {
       min-height: 80vh;
       width: 100%;
       display: flex;
       flex-direction: column;
       border-bottom: 2px solid black;
    }

    .market-left-top-div, .market-left-bottom-div {
        width: 100%;
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .market-left-top-div {
        border-bottom: 2px solid black;
    }

   .market-right-div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .market-header-div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%; 
    }

    .market-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .market-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .market-descrip-div {
        width: 80%;
    }

    .market-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

    .resume-email {
        font-weight: 600;
        line-height: 1.5;
        padding-left: 10px;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .market-video-wrapper {
        /* background-color: #5b7cfc; */
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }

    .market-video-wrapper > div {
       margin-bottom: 5em;
       box-shadow: rgba(91, 124, 252, 0.8) 5px 5px, rgba(97, 129, 253, 0.5) 10px 10px, rgba(134, 158, 250, 0.3) 15px 15px, rgba(154, 173, 250, 0.2) 20px 20px, rgba(187, 199, 250, 0.1) 25px 25px;
    }

    .youtube-header {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.3;
        padding: 3em;
        text-align: center;
    }

    .youtube-header a {
        font-weight: 600;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        padding: 0 10px;
    }


    
}


@media screen and (max-width: 420px) {
    .market-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .market-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black
    }

    .market-left-div {
       min-height: 80vh;
       width: 100%;
       display: flex;
       flex-direction: column;
       border-bottom: 2px solid black;
    }

    .market-left-top-div, .market-left-bottom-div {
        width: 100%;
        min-height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .market-left-top-div {
        border-bottom: 2px solid black;
    }

   .market-right-div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .market-header-div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%; 
    }

    .market-header {
        font-size: 2.8em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .market-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .market-descrip-div {
        width: 80%;
    }

    .market-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

    .resume-email {
        font-weight: 600;
        line-height: 1.5;
        padding-left: 10px;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .market-video-wrapper {
        /* background-color: #5b7cfc; */
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }

    .market-video-wrapper > div {
       margin-bottom: 5em;
       box-shadow: rgba(91, 124, 252, 0.8) 5px 5px, rgba(97, 129, 253, 0.5) 10px 10px, rgba(134, 158, 250, 0.3) 15px 15px, rgba(154, 173, 250, 0.2) 20px 20px, rgba(187, 199, 250, 0.1) 25px 25px;
    }

    .youtube-header {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.3;
        padding: 3em;
        text-align: center;
    }

    .youtube-header a {
        font-weight: 600;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        padding: 0 10px;
    }


    
}

