@media screen and (min-width: 961px) {
  .app-outer-div {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-family: 'Raleway', Helvetica, sans-serif; */
    /* font-family: 'Epilogue', sans-serif; */
    font-family: 'Poppins', sans-serif;
    color: #404040;
  }
}


@media screen and (max-width: 960px) {
    .app-outer-div {
      /* background-color: gray; */
      width: 100vw;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* font-family: 'Raleway', Helvetica, sans-serif; */
      /* font-family: 'Epilogue', sans-serif; */
      font-family: 'Poppins', sans-serif;
      color: #404040
    }
}