@media screen and (min-width: 961px) {
    /* DEVELOP COMPONENT */
    .develop-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .develop-inner-div {
        background-color: white;
        min-width: 1200px;
        max-width: 1200px;
        margin-top: 20vh;
        /* min-height: 80vh;
        max-height: 80vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black;
    }

    .develop-top-div {
        /* background-color: antiquewhite; */
        width: 100%;
        display: flex;
        overflow: hidden;
        border-bottom: 2px solid black;
    }

    .develop-bottom-div {
        width: 100%;
        height: 300px;
        display: flex;
        overflow: hidden;
    }

    .develop-top-left-div, .develop-top-right-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        overflow: hidden;
    }

    .develop-top-left-div {
        width: 40%;
    }

    .develop-top-right-div {
        width: 60%;
        border-left: 2px solid black;
    }

   
    .develop-header-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 80%; 
        height: 100%;
    }

    .develop-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .develop-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .develop-descrip-div {
        width: 90%;
        padding: 0 3em;
    }

    .develop-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

   .develop-descrip  a {
        font-weight: 600;
        padding: 0 7px;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .develop-stage-skills-wrapper {
        margin-top: 2%;
        border: 1px;
        display: flex;
        gap: 5em;
        /* flex-direction: column; */
        /* align-items: center; */
        justify-content: center;
        height: 100vh;
        width: 100%;
        position: relative;
    }

    .develop-stage-skills-header {
        /* color: #389CF4; */
        font-weight: 600;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        font-size: 30px;
        margin-left: 50px;
    }

    .develop-stage-skills-list {
        width: 80%;
        height: 230px;
        align-items: center;
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        flex: 1 1 160px;
    }

    .develop-skill-wrapper {
        width: 15%;
        height: 100px;
    }

    .skill {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;   
    }

    #skill-icon {
        color: #404040;
        font-size: 2.2em;
    }

    .skill p {
        font-size: 1em;
        font-weight: 600;
        color: #5b7cfc;
        padding-top: 10px;
    }

}

@media screen and (min-width: 421px) and (max-width: 960px) {
    /* DEVELOP COMPONENT */
    .develop-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .develop-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black;
    }

    .develop-top-div {
        /* background-color: antiquewhite; */
        width: 100%;
        /* min-height: 40vh; */
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 2px solid black;
    }

    .develop-bottom-div {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .develop-top-left-div, .develop-top-right-div {
        /* border: 1px solid; */
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        /* height: 40vh; */
        overflow: hidden;
    }

    .develop-top-left-div {
        width: 100%;
        height: 40vh;
    }

    .develop-top-right-div {
        width: 100%;
        height: 40vh;
        border-top: 2px solid black;
    }

   
    .develop-header-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 90%; 
        height: 100%;
    }

    .develop-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .develop-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .develop-descrip-div {
        width: 90%;
    }

    .develop-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

   .develop-descrip  a {
        font-weight: 600;
        padding: 0 10px;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .develop-stage-skills-wrapper {
        margin-top: 2%;
        border: 1px;
        display: flex;
        gap: 1em;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        height: 80%;
        width: 100%;
        position: relative;
    }

    .develop-stage-skills-header {
        /* color: #389CF4; */
        font-weight: 600;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        font-size: 30px;
        margin-left: 25px;
    }

    .develop-stage-skills-list {
        width: 100%;
        align-items: center;
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        flex: 1 1 160px;
    }

    .develop-skill-wrapper {
        /* background-color: #389CF4; */
        width: 25%;
        height: 130px;
    }

    .develop-skill-wrapper:last-child {
       border: none;
    }

    .skill {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;   
    }

    #skill-icon {
        color: #404040;
        font-size: 2.5em;
    }

    .skill p {
        font-size: 1em;
        font-weight: 600;
        color: #5b7cfc;
        padding-top: 10px;
    }

}

@media screen and (max-width: 420px) {
    /* DEVELOP COMPONENT */
    .develop-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .develop-inner-div {
        background-color: white;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
        overflow: hidden;
        border: 2px solid black;
    }

    .develop-top-div {
        /* background-color: antiquewhite; */
        width: 100%;
        /* min-height: 40vh; */
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 2px solid black;
    }

    .develop-bottom-div {
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .develop-top-left-div, .develop-top-right-div {
        /* border: 1px solid; */
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        /* height: 40vh; */
        overflow: hidden;
    }

    .develop-top-left-div {
        width: 100%;
        height: 40vh;
    }

    .develop-top-right-div {
        width: 100%;
        height: 40vh;
        border-top: 2px solid black;
    }

   
    .develop-header-div {
        /* border: 1px solid; */
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 90%; 
        height: 100%;
    }

    .develop-header {
        font-size: 3em;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
    }

    .develop-header span {
        text-decoration: underline;
        text-decoration-color: #5b7cfc;
        text-underline-offset: 5px;
    }

    .develop-descrip-div {
        width: 90%;
    }

    .develop-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.5;
        width: 95%;
    }

   .develop-descrip  a {
        font-weight: 600;
        padding: 0 10px;
        color: #5b7cfc;
        text-decoration: underline;
        text-decoration-thickness: 2px;
    }

    .develop-stage-skills-wrapper {
        margin-top: 5%;
        border: 1px;
        display: flex;
        flex-direction: column;
        height: 80%;
        width: 100%;
        position: relative;
    }

    .develop-stage-skills-header {
        /* color: #389CF4; */
        font-weight: 600;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        font-size: 30px;
        margin-left: 25px;
    }

    .develop-stage-skills-list {
        width: 100%;
        align-items: center;
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        flex: 1 1 160px;
    }

    .develop-skill-wrapper {
        /* background-color: #389CF4; */
        width: 25%;
        height: 130px;
    }

    .develop-skill-wrapper:last-child {
       border: none;
    }

    .skill {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;   
    }

    #skill-icon {
        color: #404040;
        font-size: 2.5em;
    }

    .skill p {
        font-size: 1em;
        font-weight: 600;
        color: #5b7cfc;
        padding-top: 10px;
    }

}
