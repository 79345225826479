@media screen and (min-width: 961px) {
    .about-outer-div {
        position: relative;
        width: 100vw;
        /* height: 100vh; */
        display: flex;
        justify-content: center;
        /* align-items: center; */
        overflow: hidden;
    }

    .about-inner-div {
        position: relative;
        min-width: 1200px;
        max-width: 1200px;
        height: 650px;
        margin-top: 20vh;
        display: flex;
        align-items: center;
        overflow: hidden;
        border: 2px solid black;
    }

    .about-left-div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        overflow: hidden;
        position: relative;
    }

    .about-right-div {
        width: 50%;
        height: 100%;
        display: flex;
        overflow: hidden;
        position: relative;
        align-items: flex-end;
    }

    .about-img-wrapper {
        /* border: 1px solid red; */
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .about-img-wrapper-inner {
        /* border: 1px solid blue; */
        width: 80%;

    }

    .header-wrapper {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
    }

    .about-header-first {

        width: fit-content;
        padding: 0.3em;
        /* color: #389CF4; */
        font-weight: 300;
        font-size: 2em;
        /* margin-top: 10px; */
    }

    .about-header-second {  
        margin-top: 0.3px;
        margin-left: 80px;
        border: 2px solid black;
        width: fit-content;
        padding: 0.3em;
        font-size: 3em;
        font-weight: 600;
        overflow: hidden;
    }

    .about-descrip-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        line-height: 1.5;
        padding-bottom: 5em;
    }

    .about-text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 6em;
        width: 100%;
        overflow: hidden;
    }

    .about-descrip-text {
        border: 2px solid black;
        width: fit-content;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
    }

    .about-descrip-text h1 {
        font-size: 1em;
        padding: 0 1em;
    }
    
    .about-descrip-div span {
        padding: 0.5em;
        height: 100%;
        font-size: 1.5em;
        /* padding-right: 10px; */
        border-right: 2px solid black;
    }

    .about-pic {
        width: 400px;
        height: 400px;
        object-fit: cover;
        border-radius: 50%;
        
    }

    .jojo-pic {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-position: 80%;
        object-fit: cover;
        position: absolute;
        top: 40%;
        left: 60%;
    }

 
}


@media screen and (min-width: 421px) and (max-width: 960px) {
    .about-outer-div {
        position: relative;
        width: 100vw;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .about-inner-div {
        position: relative;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        border: 2px solid black;
    }

    .about-left-div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .about-right-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        align-items: flex-end;
    }

    .about-img-wrapper {
        width: 100%;
        height: 380px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .about-img-wrapper-inner {
        /* border: 1px solid blue; */
        width: 80%;

    }

    .header-wrapper {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
    }

    .about-header-first {

        width: fit-content;
        padding: 0.3em;
        /* color: #389CF4; */
        font-weight: 300;
        font-size: 2em;
        /* margin-top: 10px; */
    }

    .about-header-second {  
        margin-top: 0.3px;
        margin-left: 80px;
        border: 2px solid black;
        width: fit-content;
        padding: 0.3em;
        font-size: 3em;
        font-weight: 600;
        overflow: hidden;
    }

    .about-descrip-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        line-height: 1.5;
        padding-bottom: 5em;
    }

    .about-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6em;
        width: 100%;
        overflow: hidden;
    }

    .about-descrip-text {
        border: 2px solid black;
        width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
    }

    .about-descrip-text h1 {
        font-size: 1em;
        padding: 0 1em;
    }
    
    .about-descrip-div span {
        padding: 0.5em;
        height: 100%;
        font-size: 1.5em;
        /* padding-right: 10px; */
        border-right: 2px solid black;
    }

    .about-pic {
        width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 50%;
    }

    .jojo-pic {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-position: 80%;
        object-fit: cover;
        position: absolute;
        top: 40%;
        left: 60%;
    }

 
}



@media screen and (max-width: 420px) {
    .about-outer-div {
        position: relative;
        width: 100vw;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .about-inner-div {
        position: relative;
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        border: 2px solid black;
    }

    .about-left-div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    .about-right-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        align-items: flex-end;
    }

    .about-img-wrapper {
        width: 100%;
        height: 380px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .about-img-wrapper-inner {
        /* border: 1px solid blue; */
        width: 80%;

    }

    .header-wrapper {
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
    }

    .about-header-first {

        width: fit-content;
        padding: 0.3em;
        /* color: #389CF4; */
        font-weight: 300;
        font-size: 2em;
        /* margin-top: 10px; */
    }

    .about-header-second {  
        margin-top: 0.3px;
        margin-left: 80px;
        border: 2px solid black;
        width: fit-content;
        padding: 0.3em;
        font-size: 2.5em;
        font-weight: 600;
        overflow: hidden;
    }

    .about-descrip-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        line-height: 1.5;
        padding-bottom: 5em;
        
    }

    .about-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6em;
        width: 100%;
        overflow: hidden;
    }

    .about-descrip-text {
        border: 2px solid black;
        width: 85%;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
    }

    .about-descrip-text h1 {
        font-size: 0.8em;
        /* padding: 0 1em; */
        padding-left: 0.5em;
    }
    
    .about-descrip-div span {
        padding: 0.5em;
        height: 100%;
        font-size: 1.5em;
        /* padding-right: 10px; */
        border-right: 2px solid black;
    }

    .about-pic {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 50%;
    }

    .jojo-pic {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-position: 80%;
        object-fit: cover;
        position: absolute;
        top: 40%;
        left: 60%;
    }

 
}