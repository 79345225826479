
@media screen and (min-width: 1300px)  {
    .experience-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 1200px;
        max-width: 1200px;
    }

}


@media screen and (max-width: 1299px) {
   .experience-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
    }
}

/*  MARKET COMPONENT  */
