@media screen and (min-width: 961px) {
    .contact-outer-div {
        /* background-color: #389CF4; */
        position: relative;
        width: 100vw;
        height: 600px;
        border-bottom: 10px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border-top: 1px solid black; */
    }

    .contact-inner-div {
        margin-top: 10vh;
        min-width: 1200px;
        max-width: 1200px;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .contact-wrapper {
        display: flex;
        gap: 10em;
        width: 100%;
        align-items: center;
        /* border: 2px solid black; */
    }

    .contact-header-div {
        font-weight: 100;
        line-height: 1.3;
        font-size: 5em;
        font-weight: 600;
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .contact-header {
        margin-left: 30px;
        /* color: #5b7cfc; */
    }

    .contact-info-wrapper {
        display: flex;
        flex-direction: column;
        width: 70%;
        border: 2px solid black;
    }

    .contact-info-left {
        display: flex;
        width: 100%;
    }

    .contact-info-div {
        /* background-color: antiquewhite; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        width: 100%;
        height: 15vh;
    }

    .contact-info-div:first-child {
        border-bottom: 2px solid black;
    }

    .icon-div {
        border-right: 2px solid black;
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-div-1 {
        font-weight: 600;
        width: 35%;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 2px solid black;
    }

    .info-div-2 {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .contact-icon {
        font-size: 2em;
    }

    .contact, .contact-email {
        font-size: 1em;
        font-weight: 600;
        color:#5b7cfc ;
    }

    .contact-email:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 3px;
    }

    .contact-links-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2em;
    }

}

@media screen and (min-width: 421px) and (max-width: 960px) {
    .contact-outer-div {
        /* background-color: #389CF4; */
        position: relative;
        width: 100vw;
        border-bottom: 10px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border-top: 1px solid black; */
    }

    .contact-inner-div {
        margin-top: 20vh;
        min-width: 90vw;
        max-width: 90vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5em;
        width: 100%;
        align-items: center;
        margin-bottom: 20%;
        /* border: 2px solid black; */
    }

    .contact-header-div {
        font-weight: 100;
        line-height: 1.3;
        font-size: 4em;
        font-weight: 600;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .contact-header {
        margin-left: 30px;
        /* color: #5b7cfc; */
    }

    .contact-info-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 2px solid black;
    }

    .contact-info-left {
        display: flex;
        width: 100%;
    }

    .contact-info-div {
        /* background-color: antiquewhite; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        width: 100%;
        height: 15vh;
    }

    .contact-info-div:first-child {
        border-bottom: 2px solid black;
    }

    .icon-div {
        border-right: 2px solid black;
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-div-1 {
        font-weight: 600;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 2px solid black;
    }

    .info-div-2 {
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .contact-icon {
        font-size: 2em;
    }

    .contact, .contact-email {
        font-size: 1em;
        font-weight: 600;
        color:#5b7cfc ;
    }

    .contact-email:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 3px;
    }

    .contact-links-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2em;
    }

}

@media screen and  (max-width: 420px) {
    .contact-outer-div {
        /* background-color: #389CF4; */
        position: relative;
        width: 100vw;
        border-bottom: 10px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* border-top: 1px solid black; */
    }

    .contact-inner-div {
        margin-top: 10vh;
        min-width: 90vw;
        max-width: 90vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5em;
        width: 100%;
        align-items: center;
        margin-bottom: 20%;
        /* border: 2px solid black; */
    }

    .contact-header-div {
        font-weight: 100;
        line-height: 1.3;
        font-size: 3em;
        font-weight: 600;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-align: center;
    }


    .contact-info-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 2px solid black;
    }

    .contact-info-left {
        display: flex;
        width: 100%;
    }

    .contact-info-div {
        /* background-color: antiquewhite; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1em;
        width: 100%;
        height: 10vh;
    }

    .contact-info-div:first-child {
        border-bottom: 2px solid black;
    }

    .icon-div {
        border-right: 2px solid black;
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-div-1 {
        display: none;
    }

    .info-div-2 {
        width: 75%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .contact-icon {
        font-size: 2em;
    }

    .contact, .contact-email {
        font-size: 1em;
        font-weight: 600;
        color:#5b7cfc ;
    }

    .contact-arrow {
        font-size: 1.5em;
    }

    .contact-email:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 3px;
    }

    .contact-links-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2em;
    }

}

