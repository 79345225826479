@media screen and (min-width: 961px) {

    .projects-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .projects-inner-div {
        min-width: 1200px;
        max-width: 1200px;
        margin-top: 10vh;
        /* min-height: 80vh;
        max-height: 80vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        /* border: 2px solid black; */
    }


    .develop-stage-project-header-wrapper {
        font-size: 5em;
        /* font-weight: 600; */
        overflow: hidden;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
    }
  
    .develop-projects-wrapper {
        /* background-color: #389CF4; */
        width: 100%;
        /* min-height: 40vh; */
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }

    .develop-projects-card-div {
        border: 2px solid black;
        margin-top: 10vh;
    }

    .projects-card-outer-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .projects-card-wrapper {
        position: relative;
        overflow: hidden;
        min-height: 500px;
        display: flex;
        max-width: 1200px;
    }

    .projects-card-right, .projects-card-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .projects-card-left {
        width: 65%;
    }

    .projects-card-right {
        width: 35%;
    }

    .projects-card-right {
        border-left: 2px solid black;
    }

    .projects-img-outer-wrapper {
        width: 100%;
        min-height: 400px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .projects-img-inner-wrapper {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        height: 100%;
        gap: 2em;
        width: 90%;
    }

    .projects-img-inner-wrapper::-webkit-scrollbar {
        border: 1px solid black;
    }

    .projects-img-inner-wrapper::-webkit-scrollbar-thumb{
         background-color: #404040;
    }

    .project-img {
        min-width: 500px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

    .projects-card-text-wrapper {
        /* background-color:rgba(50, 50, 93, 0.25); */
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 30px;
    }

    .projects-card-header {
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 18px;
        padding-bottom: 15px;
    }

    .projects-card-name {
        font-weight: 600;
        font-size: 1.5em;
        padding-bottom: 20px;
        color: #5b7cfc ;
    }

    .projects-card-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.3;
    }

    .projects-links-wrapper {
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 2px solid black;
    }

    .projects-link:first-child {
        border-bottom: 2px solid black;
    }

    .projects-link {
        font-weight: 600;
        font-size: 1.3em;
        letter-spacing: 0.1em;
        color: #404040;
        display: flex;
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .projects-link:hover {
        background-color: #5b7cfc;
        color: white;
        font-weight: 600;
        font-size: 1.5em;
        text-transform: uppercase;
        letter-spacing: 0;
        color: white;
        transition: all 0.2s ease-in-out ;
    }

}

@media screen and (min-width: 421px) and (max-width: 960px) {

    .projects-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .projects-inner-div {
        min-width: 90vw;
        max-width: 90vw;
        margin-top: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        /* border: 2px solid black; */
    }


    .develop-stage-project-header-wrapper {
        letter-spacing: 0.1em;
        font-size: 3.5em;
        font-weight: 600;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 1.2;
    }

  
    .develop-projects-wrapper {
        /* background-color: #389CF4; */
        width: 100%;
        /* min-height: 40vh; */
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }

    .develop-projects-card-div {
        border: 2px solid black;
        margin-top: 10vh;
    }

    .projects-card-outer-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .projects-card-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        min-height: 60vh;
        display: flex;
        max-width: 1200px;
    }

    .projects-card-right, .projects-card-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .projects-img-outer-wrapper {
        width: 100%;
        height: 500px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .projects-img-inner-wrapper {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        height: 80%;
        gap: 2em;
        width: 90%;
    }

    .projects-img-inner-wrapper::-webkit-scrollbar {
        border: 1px solid black;
    }

    .projects-img-inner-wrapper::-webkit-scrollbar-thumb{
         background-color: #404040;
    }

    .project-img {
        min-width: 450px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

    .projects-card-text-wrapper {
        /* background-color:rgba(50, 50, 93, 0.25); */
        width: 90%;
        height: 25vh;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }

    .projects-card-header {
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 18px;
        padding-bottom: 15px;
    }

    .projects-card-name {
        font-weight: 600;
        font-size: 1.5em;
        padding-bottom: 20px;
        color: #5b7cfc ;
    }

    .projects-card-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.3;
    }

    .projects-links-wrapper {
        height: 150px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 2px solid black;
    }

    .projects-link:first-child {
        border-bottom: 2px solid black;
    }

    .projects-link {
        font-weight: 600;
        font-size: 1.3em;
        letter-spacing: 0.1em;
        color: #404040;
        display: flex;
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .projects-link:hover {
        background-color: #5b7cfc;
        color: white;
        font-weight: 600;
        font-size: 1.5em;
        text-transform: uppercase;
        letter-spacing: 0;
        color: white;
        transition: all 0.2s ease-in-out ;
    }

}


@media screen and (max-width: 420px) {

    .projects-outer-div {
        /* background-color: rgb(241, 241, 241); */
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .projects-inner-div {
        min-width: 100vw;
        max-width: 100vw;
        margin-top: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        /* border: 2px solid black; */
    }

    .develop-stage-project-header-wrapper {
        letter-spacing: 0.1em;
        font-size: 3.5em;
        font-weight: 600;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 1.2;
    }

  
    .develop-projects-wrapper {
        /* background-color: #389CF4; */
        width: 100%;
        /* min-height: 40vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    .develop-projects-card-div {
        border: 2px solid black;
        margin-top: 10vh;
        width: 90vw;
    }

    .projects-card-outer-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .projects-card-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        min-height: 60vh;
        display: flex;
        max-width: 1200px;
    }

    .projects-card-right, .projects-card-left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .projects-img-outer-wrapper {
        width: 100%;
        height: 350px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .projects-img-inner-wrapper {
        display: flex;
        align-items: center;
        overflow-x: scroll;
        height: 80%;
        gap: 2em;
        width: 90%;
    }

    .projects-img-inner-wrapper::-webkit-scrollbar {
        border: 1px solid black;
    }

    .projects-img-inner-wrapper::-webkit-scrollbar-thumb{
         background-color: #404040;
    }

    .project-img {
        min-width: 300px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }

    .projects-card-text-wrapper {
        /* background-color:rgba(50, 50, 93, 0.25); */
        width: 90%;
        height: 25vh;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
    }

    .projects-card-header {
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 18px;
        padding-bottom: 15px;
    }

    .projects-card-name {
        font-weight: 600;
        font-size: 1.5em;
        padding-bottom: 20px;
        color: #5b7cfc ;
    }

    .projects-card-descrip {
        font-size: 1em;
        font-weight: 300;
        line-height: 1.3;
    }

    .projects-links-wrapper {
        height: 150px;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 2px solid black;
    }

    .projects-link:first-child {
        border-bottom: 2px solid black;
    }

    .projects-link {
        font-weight: 600;
        font-size: 1.3em;
        letter-spacing: 0.1em;
        color: #404040;
        display: flex;
        width: 100%;
        height: 50%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .projects-link:hover {
        background-color: #5b7cfc;
        color: white;
        font-weight: 600;
        font-size: 1.5em;
        text-transform: uppercase;
        letter-spacing: 0;
        color: white;
        transition: all 0.2s ease-in-out ;
    }

}